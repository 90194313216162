import React, {Component} from 'react';
import {graphql} from 'gatsby';

import SEO from '../components/shared/SEO';
import {Navbar} from '../components/shared/Navbar';
import BannerAbout from '../components/aboutpage/BannerAbout';
import ContentAbout from '../components/aboutpage/ContentAbout';
import PhotosAbout from '../components/aboutpage/PhotosAbout';
import Footer from '../components/shared/Footer';
import styles from './about.module.scss';
import registerIcons from '../styles/registerIcons';
import createObserver from '../utils/IntersectionObserver';

// font awesome library needs to be created via entry point of app
// so needed here in case of refresh on this screen
registerIcons();

class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      render_arrow: false
    };

    this.top_content = React.createRef();
    this.scrollToTopContent = this.scrollToTopContent.bind(this);
    this.setComponentState = this.setComponentState.bind(this);
  }

  scrollToTopContent() {
    this.top_content.current.scrollIntoView({behavior: 'smooth'});
  }

  setComponentState(key, value) {
    this.setState({[key]: value});
  }

  componentDidMount() {
    // show arrow only if browser can use IntersectionObserver
    if (typeof window.IntersectionObserver != 'undefined') {
      const ratio = 0.05;
      const threshold = [0, 0.05, 0.2, 0.4, 0.6, 0.8, 1];
      const observer = createObserver(ratio, this.setComponentState, threshold);
      if (this.top_content && this.top_content.current)
        observer.observe(this.top_content.current);
    }
  }

  render() {
    const {data} = this.props;
    const {markdownRemark} = data;
    const {frontmatter} = markdownRemark;
    const {page_description, page_title} = frontmatter;
    return (
      <div>
        <header>
          <SEO title={page_title} description={page_description} />
          <Navbar />
        </header>
        <main className={styles.about}>
          <BannerAbout
            frontmatter={markdownRemark.frontmatter}
            render_arrow={this.state.render_arrow}
            scrollToTopContent={this.scrollToTopContent}
          />
          <div ref={this.top_content}>
            <ContentAbout frontmatter={markdownRemark.frontmatter} />
          </div>
          <PhotosAbout />
        </main>
        <Footer />
      </div>
    );
  }
}
export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/about-page/" } }) {
      html
      frontmatter {
        page_title
        page_description
        header_description
        page_header
        banner_image_alt
        para_1
        para_2
        para_3
        sub_heading_1
        sub_heading_2
        sub_heading_3
      }
    }
  }
`;
export default AboutPage;
