import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';
import EmployeeAbout from './EmployeeAbout';

import styles from './photosAbout.module.scss';

export class PurePhotosAbout extends Component {

  getRolloverImage(image_name) {
    const rollover_array = this.props.data.rollover.edges;
    image_name = image_name + '_2';
    let rollover_image = rollover_array.find(({node}) => {
      return node.name == image_name;
    });

    return rollover_image;
  }

  getEmployeeArray() {
    const image_array = this.props.data.images.edges;
    const bio_array = this.props.data.bios.edges;

    const employee_array = bio_array.map(({node}) => {

      let employee_bio = node.frontmatter;
      let current_employee = node.frontmatter.image_name;

      let current_employee_image = image_array.find(({node}) => {
        return node.name.split('.')[0] == current_employee;
      });

      employee_bio.image = current_employee_image;
      employee_bio.rollover_image = this.getRolloverImage(employee_bio.image_name);

      return employee_bio;
    });

    return employee_array;
  }




  renderEmployees() {
    const employee_array = this.getEmployeeArray();

    const employee_images = employee_array.map((employee) => {

      return (
        <EmployeeAbout employee={employee} key={employee.image_name} />
      );
    });

    return employee_images;
  }

  render() {
    return (
      <section className={styles.photosSection} aria-label='employee photos'>
        <div className={'offset-md-1 col-md-9'}>
          <div className={'row' + ' ' + styles.imageRow}>
            {this.renderEmployees()}
          </div>
        </div>
      </section>
    );
  }
}

export const PhotosAbout = props => (
  <StaticQuery
    query={graphql`{
  images: allFile(
    filter: {relativePath: {regex: "/about-images/"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        childImageSharp {
          gatsbyImageData(width: 157, height: 208, quality: 100, layout: FIXED)
        }
        name
      }
    }
  }
  bios: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/bios/"}}
    sort: {fields: frontmatter___image_name}
  ) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          image_name
          image_alt_text
          rollover_image_alt_text
          name
          title
          bio
        }
      }
    }
  }
  rollover: allFile(
    filter: {relativePath: {regex: "/about-color-images/"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        childImageSharp {
          gatsbyImageData(width: 157, height: 208, quality: 100, layout: FIXED)
        }
        name
      }
    }
  }
}
`}
    render={data => <PurePhotosAbout {...props} data={data} />}
  />
);

export default PhotosAbout;