import React, {Component} from 'react';

import styles from './contentAbout.module.scss';

export class ContentAbout extends Component {
  render() {
    const {frontmatter} = this.props;
    const {para_1, para_2, para_3, sub_heading_1, sub_heading_2, sub_heading_3} = frontmatter;

    return (
      <section className={styles.contentSection} aria-label='main content'>

        <div className={'offset-md-4 offset-lg-6 col-md-6 col-lg-4' + ' ' + styles.contentParagraph}>
          <h2 className={styles.h2}>{sub_heading_1}</h2>
          <p className={styles.paragraph}>
            {para_1}
          </p>
        </div>

        <div className={'offset-md-3 offset-lg-4 col-md-7 col-lg-6' + ' ' + styles.contentParagraph}>
          <h2 className={styles.h2}>{sub_heading_2}</h2>
          <p className={styles.paragraph}>
            {para_2}
          </p>
        </div>

        <div className={'offset-md-2 col-md-8' + ' ' + styles.contentParagraph}>
          <h2 className={styles.h2}>{sub_heading_3}</h2>
          <p className={styles.paragraph}>
            {para_3}
          </p>
        </div>

      </section>
    );
  }
}

export default ContentAbout;