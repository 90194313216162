import React, {Component} from 'react';
import {Link} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

import styles from './employeeAbout.module.scss';

export class EmployeeAbout extends Component {

  getEmployeeTitle(employee) {
    if (employee.image_name != 'Zy_Silhouette')
      return  <p className={styles.title}>{employee.title}</p>;
    else
      return <p className={styles.title}><Link  id="silhouette-link" to='/careers/#opportunities' className={styles.link}>{employee.title}</Link></p>;
  }

  render() {
    const {employee} = this.props;

    return (
      <div
        tabIndex={0}
        key={employee.name}
        className={styles.employeeDiv}
        id="employee"
      >
        <div className={styles.imageDiv}>

          <div
            className={styles.imageRollover}
            id="employee-rollover"
          >
            <GatsbyImage
              image={employee.rollover_image.node.childImageSharp.gatsbyImageData}
              alt={employee.rollover_image_alt_text}
            />
          </div>
          <div
            id="employee-headshot"
            className={styles.imageHeadshot}
          >
            <GatsbyImage
              image={employee.image.node.childImageSharp.gatsbyImageData}
              alt={employee.image_alt_text}
            />
          </div>
        </div>
        <div className={styles.bioDiv}>
          <h3 className={styles.name}>{employee.name}</h3>
          {this.getEmployeeTitle(employee)}
          <p className={styles.bio}>{employee.bio}</p>
        </div>
      </div>
    );
  }
}

export default EmployeeAbout;