import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

import Arrow from '../shared/Arrow';
import styles from './bannerAbout.module.scss';

export class PureBannerAbout extends Component {

  render() {
    const {frontmatter, render_arrow, scrollToTopContent} = this.props;
    const {banner_image_alt, header_description, page_header} = frontmatter;

    return (
      <section role='banner'>
        <GatsbyImage
          image={this.props.data.file.childImageSharp.gatsbyImageData}
          alt={banner_image_alt}
          className={'d-none d-md-block'}
        />
        <GatsbyImage
          image={this.props.data.file.childImageSharp.gatsbyImageData}
          alt={banner_image_alt}
          style={{height: '930px', marginBottom: '-10rem'}}
          objectFit="cover"
          objectPosition="30%"
          className={'d-block d-md-none'}
        />
        <div className={styles.title}>
          <h1 className={styles.header}>{page_header}</h1>
          <p className={styles.description}>
            {header_description}
          </p>
        </div>
        <Arrow
          arrow_color='#FFFFFF'
          href="#about-top-content"
          scrollToTopContent={scrollToTopContent}
          render_arrow={render_arrow}
        />
      </section>
    );
  }
}

export const BannerAbout = props => (
  <StaticQuery
    query={graphql`{
  file(relativePath: {eq: "images/mountain-road.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`}
    render={data => <PureBannerAbout {...props} data={data} />}
  />
);

export default BannerAbout;